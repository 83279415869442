import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import shareIcon from "./assets/solar_share-broken.png";
import mapIcon from "../../Components/TripCard/assets/map_logo.png";
import leftArrow from "./assets/left_arrow.svg";
import { FormControl } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { base_api } from "../../utils/apis";
import { isLogedIn } from "../../utils/token";
import Swal from "sweetalert2";

export default function ShowTrip() {
  const [selectedImage, setSelectedImage] = useState(0);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [information, setInformation] = useState([]);
  const [images, setImages] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [serveses, setServeses] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  const getInfo = async () => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/tours/get-tour/${id}`
      );
      setInformation(response.data.data);
      setImages(response.data.data.photos);
      setAdditionalData(response.data.data.additional_data);
      setServeses(response.data.data.services);
    } catch (error) {
      console.log(error);
    }
  };

  const [startTourDate, setStartTourDate] = useState("");
  const [visitorsNumber, setVisitorsNumber] = useState(0);
  const [childrenNumber, setChildrenNumber] = useState(0);

  const handleSubmit = async () => {
    const token = isLogedIn();
    if (!token) {
      Swal.fire({
        title: "Not Logged In",
        text: "Please log in to proceed with your order.",
        icon: "warning",
      });
      return;
    }
    try {
      const res = await axios.post(
        `${base_api}/${i18n.language}/api/mobile/orders/store`,
        {
          start_tour_date: startTourDate,
          vistiors_number: visitorsNumber,
          children_number: childrenNumber,
          tour_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Order Response:", res.data);

      const orderID = res.data.data.id;
      console.log("Order ID:", orderID);
      window.localStorage.setItem("orderID_to_payment", orderID);
      handlepayment();
    } catch (error) {
      console.error("Error submitting order:", error);
      Swal.fire({
        title: "Error",
        text: "Please check your order data and try again 😢🤦‍♀️",
        icon: "error",
      });
    }
  };

  // Request to payment API
  const handlepayment = async () => {
    const token = isLogedIn();
    if (!token) {
      Swal.fire({
        title: "Not Logged In",
        text: "Please log in to proceed with your order.",
        icon: "warning",
      });
      return;
    }
    const orderIDLocal = window.localStorage.getItem("orderID_to_payment");
    console.log("Stored Order ID:", orderIDLocal);
    try {
      const paymentRes = await axios.post(
        "https://alrmoz.com/marsa_back/public/en/api/mobile/payments/send-payment",
        {
          orderId: orderIDLocal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(paymentRes);

      // Assuming paymentRes.data contains the payment URL
      if (paymentRes.data.data.url) {
        window.open(paymentRes.data.data.url, "_blank");
      } else {
        Swal.fire({
          title: "Payment Error",
          text: "There was an issue with initiating the payment 😢",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, [i18n.language]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
        console.log("Successfully shared");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <main>
      <div className="container w-[90%] mx-auto pb-36 mt-10">
        <div className="page_header flex flex-col sm:flex-row justify-between py-5 font-cairo font-medium">
          <BreadCrumbs category={information.trip_name} categoryId={1} />
          <div className="share mt-4 sm:mt-0">
            <button className="flex gap-2" onClick={handleShare}>
              {information.trip_name}
              <img src={shareIcon} alt="" width={24} height={24} />
            </button>
          </div>
        </div>
        <div className="gallery flex flex-col md:flex-row gap-3 pb-10">
          <div className="current_img w-full md:w-[80%] rounded-[10px] overflow-hidden h-[320px] md:h-[560px]">
            <figure className="h-full w-full relative">
              <img
                src={images[selectedImage]}
                alt=""
                className="w-full h-full object-cover"
              />
            </figure>
          </div>
          <div className="others w-full md:w-[19%] h-[200px] flex md:flex-col gap-2 md:gap-4 justify-between">
            {images.map(
              (img, index) =>
                index <= 2 && (
                  <figure
                    key={index}
                    className="h-[100%] md:h-[40%] rounded-[10px] overflow-hidden"
                    onClick={() => setSelectedImage(index)}
                  >
                    <img src={img} alt="" className="w-full" />
                  </figure>
                )
            )}
          </div>
        </div>

        <div className="info flex flex-col md:flex-row gap-4">
          <div className="content w-full md:w-[70%] flex flex-col gap-4">
            <div className="trip_name border-[0.7px] flex flex-col sm:flex-row justify-between items-center border-[rgb(186,192,202,20%)] rounded-[10px] px-6">
              <div className="right text-center sm:text-left">
                <h3 className="font-bold text-xl py-3 font-cairo">
                  {" "}
                  {information.name}{" "}
                </h3>
                <p className="flex items-center justify-center sm:justify-start gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {" "}
                    {information.country} {"."} {information.city} {"."}{" "}
                    {information.street}{" "}
                  </span>
                </p>
              </div>
              <h2 className="left flex flex-col items-center gap-2 font-cairo mt-4 sm:mt-0">
                <span className="text-[#777891] text-lg font-medium">
                  {" "}
                  {t("detailsPage.duration")}{" "}
                </span>
                <span className="text-[#FD5674] text-2xl font-bold">{`${
                  information.duration
                } ${t("detailsPage.durationByDays")}`}</span>
              </h2>
            </div>
            <div className="desc border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] py-4 px-6">
              <div className="descContent flex flex-col justify-around gap-6 pb-5">
                <h2 className=" font-cairo font-semibold text-2xl">
                  {" "}
                  {t("detailsPage.desc")}{" "}
                </h2>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <p className="font-medium font-cairo">
                  {" "}
                  {information.description}{" "}
                </p>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>
              <div className="servicesSection flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {" "}
                    {t("detailsPage.services")}{" "}
                  </h2>
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="services flex flex-wrap md:flex-nowrap gap-10 md:gap-20">
                  {Array.isArray(serveses) && serveses.length > 0 ? (
                    serveses.map((item, index) => (
                      <div
                        key={index}
                        className="service flex items-center gap-4"
                      >
                        <img src={leftArrow} alt="" />
                        <h4 className="font-cairo font-medium ">{item}</h4>
                      </div>
                    ))
                  ) : (
                    <div className="service flex items-center gap-4">
                      <img src={leftArrow} alt="" />
                      <h4 className="font-cairo font-medium ">
                        No services available
                      </h4>
                    </div>
                  )}
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>

              <div className="rulesContent flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  {" "}
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {" "}
                    {t("detailsPage.rules")}{" "}
                  </h2>{" "}
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="rules">
                  <ul className="flex flex-col gap-4">
                    {additionalData === 0 ? (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]"></li>
                    ) : Array.isArray(additionalData) &&
                      additionalData.length > 0 ? (
                      additionalData.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-4 font-cairo font-medium text-[#777891]"
                        >
                          <img src={leftArrow} alt="" /> {item}
                        </li>
                      ))
                    ) : (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]">
                        {" "}
                        <img src={leftArrow} alt="" /> {additionalData}{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* pay card */}
          <div className="cost flex flex-col gap-6 w-full md:w-[30%] border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] p-4">
            <h2 className="left flex  items-center justify-between font-cairo">
              <span className="text-[#777891] text-lg font-medium">
                {" "}
                {t("detailsPage.cost")}{" "}
              </span>
              <span className="text-[#FD5674] text-3xl font-bold">
                {" "}
                {information.price} <span className="text-xs mx-2">EUR</span>{" "}
              </span>
            </h2>
            <div className="btnsSelection flex gap-4">
              <div className="w-[100%] p-2 rounded-lg shadow flex flex-col items-center justify-center gap-2">
                <p className="capitalize font-semibold self-start">
                  Payment method
                </p>
                <label className="inline-flex justify-between w-full items-center rounded-lg p-2 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&_p]:has-[:checked]:translate-y-0 [&_p]:has-[:checked]:transition-transform [&_p]:has-[:checked]:duration-500 [&_p]:has-[:checked]:opacity-100 overflow-hidden">
                  <div className="inline-flex items-center justify-center gap-2 relative">
                    <img
                      height={30}
                      width={30}
                      src={require("./assets/imageCash.svg").default}
                      alt=""
                    />
                    <p className="font-semibold absolute inset-0 w-full whitespace-nowrap -translate-y-[110%] translate-x-full top-1 left-2 transition-all duration-700 opacity-0">
                      كاش
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="cash"
                    className="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current"
                    onChange={() => setPaymentMethod("cash")}
                  />
                </label>
                <label className="inline-flex justify-between w-full items-center rounded-lg p-2 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&_p]:has-[:checked]:translate-y-0 [&_p]:has-[:checked]:transition-transform [&_p]:has-[:checked]:duration-500 [&_p]:has-[:checked]:opacity-100 overflow-hidden">
                  <div className="inline-flex items-center justify-center gap-2 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="32"
                      height="32"
                      fill="currentColor"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M22.222 15.768l-.225-1.125h-2.514l-.4 1.117-2.015.004a4199.19 4199.19 0 0 1 2.884-6.918c.164-.391.455-.59.884-.588.328.003.863.003 1.606.001L24 15.765l-1.778.003zm-2.173-2.666h1.62l-.605-2.82-1.015 2.82zM7.06 8.257l2.026.002-3.132 7.51-2.051-.002a950.849 950.849 0 0 1-1.528-5.956c-.1-.396-.298-.673-.679-.804C1.357 8.89.792 8.71 0 8.465V8.26h3.237c.56 0 .887.271.992.827.106.557.372 1.975.8 4.254L7.06 8.257zm4.81.002l-1.602 7.508-1.928-.002L9.94 8.257l1.93.002zm3.91-.139c.577 0 1.304.18 1.722.345l-.338 1.557c-.378-.152-1-.357-1.523-.35-.76.013-1.23.332-1.23.638 0 .498.816.749 1.656 1.293.959.62 1.085 1.177 1.073 1.782-.013 1.256-1.073 2.495-3.309 2.495-1.02-.015-1.388-.101-2.22-.396l.352-1.625c.847.355 1.206.468 1.93.468.663 0 1.232-.268 1.237-.735.004-.332-.2-.497-.944-.907-.744-.411-1.788-.98-1.774-2.122.017-1.462 1.402-2.443 3.369-2.443z"></path>
                      </g>
                    </svg>
                    <p className="font-semibold absolute inset-0 w-full whitespace-nowrap translate-y-[110%] translate-x-full top-1 left-2 transition-all duration-700 opacity-0">
                      فيزا
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="card"
                    className="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current"
                    onChange={() => setPaymentMethod("card")}
                  />
                </label>
              </div>
            </div>

            {(paymentMethod === "card" || paymentMethod === "cash") && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {/* Date Field */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label shrink htmlFor="date">
                    {t("detailsPage.costForm.date")}
                  </label>
                  <input
                    id="date-input"
                    type="date"
                    onChange={(e) => setStartTourDate(e.target.value)}
                    className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </FormControl>

                {/* Visitors Field */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("detailsPage.costForm.visitors")}</label>
                  <input
                    type="number"
                    min={1}
                    placeholder="Visitors"
                    onChange={(e) =>
                      setVisitorsNumber(
                        e.target.value ? Number(e.target.value) : 0
                      )
                    }
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                {/* Children Field */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("detailsPage.costForm.childs")}</label>
                  <input
                    type="number"
                    min={1}
                    placeholder={t("detailsPage.costForm.childsPlaceholder")}
                    onChange={(e) =>
                      setChildrenNumber(
                        e.target.value ? Number(e.target.value) : 0
                      )
                    }
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`w-full py-2 px-4 rounded-lg font-cairo font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                    paymentMethod === "cash"
                      ? "bg-green-500 text-white hover:bg-green-600 focus:ring-green-600"
                      : "bg-[#FD5674] text-white hover:bg-[#e44d6a] focus:ring-[#e44d6a]"
                  }`}
                  onClick={async (e) => {
                    if (paymentMethod === "cash") {
                      e.preventDefault();
                      Swal.fire({
                        title: "process is completed payment",
                        text: "We have received your request and will contact you as soon as possible",
                        icon: "success",
                      });
                    } else {
                      e.preventDefault();
                      const button = e.currentTarget;
                      button.disabled = true;
                      button.innerHTML = "Loading...";
                      await handleSubmit();
                      button.disabled = false;
                      button.innerHTML = t("detailsPage.costForm.getThis");
                    }
                  }}
                >
                  {t("detailsPage.costForm.getThis")}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
